.news-ref-section{
  padding: rem(90px 0 60px 0);
  @include media-breakpoint-down(md) {
    padding: rem(40px 0 40px 0);
  }
  .btn{
    min-width: rem(177px);
  }
}
.news-main-block{
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  column-gap: rem(70px);

  flex-wrap: wrap;
  @include media-breakpoint-down(md) {
    column-gap: rem(40px);
  }
  .item{
    width: calc(50% - 2.34rem);
    padding: rem(0 0px 0 50px);
    margin-bottom: rem(45px);
    display: flex;
    justify-content: flex-start;
    align-items:stretch;
    flex-grow: 1;
    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: rem(0 3px 0px 3px);
      margin-bottom: rem(25px);
    }
    &-inner{
      border: 1px solid #1b3142;
      padding: rem(18px 27px 34px 0);
      display: flex;
      align-items: stretch;
      hyphens: auto;
      margin: 2.5rem auto 0;
      flex-grow: 1;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: flex-start;
        margin: 2.5rem auto 0;
        padding: rem(18px 0px 14px 0);
      }
      &:hover{
        .item-inner-content{
          .ttl{

          }
          .link-wrap{
            .link{
              text-decoration: none;

              &:before{
                right: 0;
              }
            }
          }
        }
      }
      .image{
        width: 100%;
        height: 100%;
        box-shadow: 1px 17px 24px 0px rgba(0, 0, 0, 0.1);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../img/no-avatar-2.jpg");
        &-wrap{
          border: rem(8px solid white);
          margin-left: rem(-49px);
          position: relative;
          margin-right: rem(28px);
          width: rem(226px);
          height: rem(226px);
          flex-shrink: 0;
          @include media-breakpoint-down(lg) {
            width: rem(186px);
            height: rem(186px);
          }
          @include media-breakpoint-down(sm) {
            margin: -3rem auto 0;
          }
        }
      }
      &-content{
        text-align: left;
        padding-top: rem(8px);
        padding-bottom: rem(15px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        @include media-breakpoint-down(sm) {
          padding: rem(8px 15px);
        }
        .ttl{
          font-size: rem(22px);
          color: cl(primary);
          line-height:rem(30px);
          text-align: left;
          font-weight: 400;
          text-decoration: none;
          margin-bottom: rem(12px);
          display: block;
          cursor: pointer;
        }
        .text{
          font-family: $font-r;
          font-size: rem(16px);
          line-height:rem(26px);
          font-weight: 400;
          color:#424242;
          p{
            font-family: $font-r;
            line-height:rem(26px);
            font-size: rem(16px);
            font-weight: 400;
            color:#424242;
            margin-bottom: 0;
          }
        }
        .link-wrap{
          margin-top: rem(20px);
          .link{
            font-size: rem(16px);
            color: cl(primary);
            line-height:rem(30px);
            text-align: left;
            font-weight: 400;
            position: relative;
            padding-right: rem(38px);
            cursor: pointer;
            white-space: nowrap;
            &:before{
              content: "";
              position: absolute;
              @extend .pos-centerY;
              right: rem(16px);
              background-image: url("../img/svg/arrow.svg");
              background-position: left center;
              background-size: contain;
              width: rem(20px);
              height: rem(10px);
              transition: 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }
}

.news-inner-page{
  padding: rem(60px 0 68px 0);
  position: relative;
  flex-grow: 1;
  .news-inner-wrap{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .main-side{
      position: relative;
      z-index: 3;
      width: calc(100% - 23.125rem);
      padding-right: rem(60px);
      color: cl(text-gray);
      font-size: rem(18px);
      line-height: rem(28px);
      font-family: $font-r;
      @include media-breakpoint-down(md) {
        width: 100%;
        padding-right: 0;
      }
      h3 {
        font-size: rem(30px);
        line-height: rem(40px);
        margin: rem(0 0 10px);
        font-family: $font;
        font-weight: 400;
        @include media-breakpoint-down(xs) {
          font-size: rem(25px);
        }
      }
      .ttl{
        font-size: rem(30px);
        line-height: rem(40px);
        margin: rem(0 0 10px);
        font-family: $font;
        font-weight: 400;
        @include media-breakpoint-down(xs) {
          font-size: rem(25px);
        }
      }
      .date{
        font-size: rem(14px);
        line-height: rem(24px);
        display: block;
        font-family: $font-r;
        color: #424242;
      }
      p{
        hyphens:auto;
        margin-bottom: 1.5rem;
      }
      ul{
        list-style: none;
        position: relative;
        z-index: 3;
        margin-bottom: 1rem;
        padding-left: 1rem;
        li{
          position: relative;
          padding: rem(0px 0 20px 25px);
          color: cl(text-gray);
          font-size: rem(18px);
          line-height: rem(28px);
          font-family: $font-r;
          &:after{
            content: '';
            position: absolute;
            top:rem(10px);
            left: 0;
            width: rem(6px);
            height: rem(6px);
            border-radius: 50%;
            line-height: rem(20px);
            text-align: center;
            font-size: rem(26px);
            color: cl(white);
            background-color: cl(primary);
          }
        }
      }
      .separation-btns-wrap{
        padding-top:rem(40px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .social-wrap{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          p{
            margin-bottom: 0;
            font-size: rem(16px);
            line-height: rem(36px);
            color: #25282a;
            font-weight: 700;
            @extend .ttu;
          }
          a{
            margin-left: 1.25rem;
            color: cl(secondary);
            font-size: rem(18px);
            line-height: rem(36px);
            &:first-child{
              margin-left: 0.75rem;
            }
          }
        }
      }
      img{
        max-width:50%;
        width: 100%;
        display: block;
        margin: 2.5rem auto 2.5rem;
        box-shadow: 1px 17px 24px 0px rgba(0, 0, 0, 0.1);
        object-fit: contain;
        max-height: rem(500px);
      }
      .new-wrap{
        max-width: 40rem;
        margin: 0 auto;
        margin-bottom: 2rem;
        width: 100%;
      }
      .video-holder{
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        width: 100%;
        iframe{
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }

    }
    .left-block{
      position: relative;
      z-index: 3;
      width: 23.125rem;
      padding-left: rem(30px);
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-top: 3rem;
      }
      &--inner{
        border: 1px solid cl(primary);
        padding: rem(27px);
      }
      .left-ttl{
          text-transform: uppercase;
          font-size: rem(22px);
          line-height: rem(32px);
          margin: rem(0 0 20px);
          font-family: $font;
          font-weight: 400;
          @include media-breakpoint-down(xs) {
            font-size: rem(18px);
          }

      }
      .more-news-block{
        list-style: none;
        margin-bottom: .5rem;
        li{
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding: rem(11px 0);
          .icon{
            display: block;
            flex-shrink: 0;
            width: rem(100px);
            height: rem(100px);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: rem(16px);
            margin-left: rem(-54px);
            box-shadow: 1px 17px 24px 0px rgba(0, 0, 0, 0.1);
          }
          a{
            font-size: rem(16px);
            line-height: rem(25px);
            color: cl(text);
            display: block;
            font-weight: 400;
            margin-bottom: 0.25rem;
            hyphens: auto;
          }
          .date{
            font-size: rem(14px);
            line-height: rem(24px);
            color: cl(text-gray);
            font-family: $font-r;
          }
        }
      }
      .link{
        font-size: rem(16px);
        color: cl(primary);
        line-height:rem(30px);
        text-align: left;
        font-weight: 400;
        position: relative;
        padding-right: rem(38px);
        cursor: pointer;
        white-space: nowrap;
        text-transform: capitalize;
        &:before{
          content: "";
          position: absolute;
          @extend .pos-centerY;
          right: rem(16px);
          background-image: url("../img/svg/arrow.svg");
          background-position: left center;
          background-size: contain;
          width: rem(20px);
          height: rem(10px);
          transition: 0.3s ease-in-out;
        }
        &:hover{
          text-decoration: none;
          &:before{
            right: 0;
          }
        }

      }
    }

  }
}