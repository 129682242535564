.home {
  &--first-text-section {
    padding: rem(95px 0);
    background-color: cl(white);
    text-align: center;
    font-size: rem(40px);
    line-height: rem(50px);
    font-weight: 400;
    letter-spacing: 0.02em;
    position: relative;
    z-index: 1;
    @include media-breakpoint-down(md) {
      font-size: rem(30px);
      line-height: rem(40px);
    }
    @include media-breakpoint-down(xs) {
      font-size: rem(25px);
      line-height: rem(35px);
    }

    p {
      margin-bottom: 0;
    }

    .decor {
      position: absolute;
      z-index: 0;
      @extend .pos-centerX;
      bottom: 0;
      width: 53vw;
      @include media-breakpoint-down(lg) {
        width: 97vw;
      }
    }
  }

  &--partners-section {
    position: relative;
    z-index: 1;
    background-color: cl(white);
    text-align: center;
    padding: rem(75px 0);

    h2 {
      color: cl(primary);
    }

    .home-brands-slider {
      margin: 2.5rem auto 0;
      max-width: rem(1000px);
      .item {
        padding: 0 0.5rem;
        &:focus {
          outline: none;
        }
        img {
          display: block;
          height: rem(110px);
          width: auto;
          object-fit: contain;
          object-position: center;
          @include media-breakpoint-down(xs) {
           // width: 30vw;
            object-position: center;
          }
          &:focus {
            outline: none;
          }

        }
      }
    }
  }
  &--join-section{
    position: relative;
    z-index: 1;
    padding: rem(35px 0 35px 0);
    background: right/cover no-repeat, cl(primary);
    color: cl(white);
    background-attachment: fixed;
    font-size: rem(20px);
    line-height: rem(30px);
    letter-spacing: 0.1em;
    font-family: $font-r;
    @include media-breakpoint-down(lg) {
      background-attachment: scroll;
      padding: rem(50px 0 50px 0);
    }
    .text-side{
      h2{
        text-transform: none;
        font-weight: 600;
        font-family: $font;
        margin-bottom: rem(30px);
        line-height: rem(60px);
      }
      p{
        margin-bottom: 0;
      }
      .btn-wrap{
        margin-top: rem(45px);
      }

    }
    .image-side {
      position: relative;
      z-index: 2;
      width: 43.9vw;
      height: 29.12vw;

      @include media-breakpoint-down(md) {
        width: 80vw;
        margin: 0 auto 3rem;
        height: 53.06vw;
      }
      .img-box{
        position: absolute;
        box-shadow: -5.209px 29.544px 68px 0px rgba(0, 0, 0, 0.48);
        height: 48.2%;
        z-index: 1;
        width: 32%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &.left-img{
          left: 0;
          @extend .pos-centerY;
        }
        &.right-img{
          right: 0;
          @extend .pos-centerY;
        }
        &.center-btn-img{
          bottom: 0;
          @extend .pos-centerX;
        }
        &.center-top-img{
          top: 0;
          @extend .pos-centerX;
        }
      }
      .book{
        position: absolute;
        left: 6%;
        width: 15.4%;
        top: 5%;
      }
      .keys{
        position: absolute;
        right: 10%;
        width: 9.6%;
        bottom:0;
      }
      .letter-decor{
        font-family: $font;
        font-size: 10.4vw;
        line-height: 10.4vw;
        position: absolute;
        //width: 100%;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1rem;
        color: rgba(255, 255, 255, 0);
        text-shadow:  -10.625px 2.847px 16px rgba(0, 0, 0, 0.15);
        text-stroke: 1px #405260;
        -webkit-text-fill-color: rgba(255, 255, 255, 0); /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #405260;
        overflow: hidden;
        z-index: 0;
        @include media-breakpoint-down(md) {
          font-size: 14.4vw;
          line-height: 14.4vw;
        }
        &.decor-top-right{
          top: -6%;
          right: 1%;
          @include media-breakpoint-down(md) {
            top:-1%;
            right: 4%;
          }
        }
        &.decor-bottom-left{
          left: -25%;
          bottom: -0.2vw;
          padding-left: 1rem;
          @include media-breakpoint-down(md) {
            left: -9%;
            bottom: 1%;
          }
        }
        .word{
          display: flex;
        }
      }
    }
  }
  &--celebrating-section{
    position: relative;
    z-index: 1;
    padding: rem(60px 0 165px 0);
    background:center/cover no-repeat, cl(primary);
    color: cl(white);
    .marque-wrap{
      position: absolute;
      left: -1rem;
      bottom: 1rem;
      z-index: 2;
      overflow: hidden;
      .marquee-celebrating {
        color: rgba(255, 255, 255, 0.502);
        font-family: $font;
        font-weight: 400;
        font-size:11vw;
        text-transform: uppercase;
        text-shadow: -10.625px 2.847px 16px rgba(0, 0, 0, 0.15);

        b {
          color: rgba(255, 255, 255, 0.502);
          font-family: $font;
          font-weight: 400;
          font-size: 11vw;
          //margin-right: 2rem;
          padding-right: 4rem;
          position: relative;
          &:after{
            content: "";
            position: absolute;
            @extend .pos-centerY;
            right: 0;
            width: rem(20px);
            height: rem(20px);
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.502);
            box-shadow: -10.625px 2.847px 16px rgba(0, 0, 0, 0.15);;
            z-index: 2;
          }
        }
      }
    }
    .home--celebrating-inner{
      position: relative;
      z-index: 3;
      margin-left: auto;
      margin-right: 7%;
      width: 41.7vw;
      @include media-breakpoint-down(md) {
        width: 90vw;
        margin: 0 auto;
      }
      h2{
        @include media-breakpoint-down(sm) {
          margin-bottom: 2rem;
          text-align: center;
        }
      }
    }

    .video-holder{
      position: relative;
      width: 100%;
      height: 23.43vw;
      display: block;
      z-index: 8;
      box-shadow: -5.209px 29.544px 68px 0px rgba(0, 0, 0, 0.48);
      @include media-breakpoint-down(md) {
        margin: 0 auto;
        height: 50.56vw;
      }
      .poster-cover{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        @extend .pos-center;
        z-index: 1;
      }
      video{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        position: absolute;
        @extend .pos-center;
        &[poster]{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      iframe{
        width: 100%!important;
        height: 100%!important;
        z-index: 0;
        position: absolute;
        @extend .pos-center;
      }
      &.active{
        .poster-cover{
          display: none;
        }
        .play{
          display: none;
        }
        &:hover{
          cursor: pointer;
          .pause{
            display: block;
          }
        }
      }
      &:hover{
        cursor: pointer;
        .play{
          width: rem(98px);
          height: rem(98px);
          @include media-breakpoint-down(md) {
            width: rem(78px);
            height: rem(78px);
          }
        }
      }
      .play{
        display: block;
        position: absolute;
        @extend .pos-centerY;
        z-index: 4;
        left: rem(-45px);
        width: rem(90px);
        height: rem(90px);
        border-radius: 50%;
        transition: 0.5s ease-in-out;
        overflow: hidden;
        transform-origin: center;
        background-color: rgb(255, 255, 255);
        border: rem(15px solid rgb(255, 255, 255));
        box-shadow: -10.625px 2.847px 16px 0px rgba(0, 0, 0, 0.15);
        @include media-breakpoint-down(md) {
          width: rem(70px);
          border: rem(10px solid rgb(255, 255, 255));
          height: rem(70px);
          left: 50%;
          transform: translate(-50%, -50%);
        }
        img{
          width: 100%;
          position: relative;
          z-index: 4;
        }
      }
      .pause{
        display: none;
        position: absolute;
        @extend .pos-centerY;
        z-index: 4;
        left: rem(-45px);
        width: rem(90px);
        height: rem(90px);
        border-radius: 50%;
        transition: 0.5s ease-in-out;
        overflow: hidden;
        transform-origin: center;
        background-color: rgb(255, 255, 255);
        border: rem(15px solid rgb(255, 255, 255));
        box-shadow: -10.625px 2.847px 16px 0px rgba(0, 0, 0, 0.15);
        @include media-breakpoint-down(md) {
          width: rem(70px);
          border: rem(10px solid rgb(255, 255, 255));
          height: rem(70px);
          left: 50%;
          transform: translate(-50%, -50%);
        }
        img{
          width: 100%;
          position: relative;
          z-index: 4;
        }
      }
    }
  }
  &--kids-testimonials-section{
    position: relative;
    z-index: 1;
    padding: rem(70px 0 210px 0);
    background:  bottom / 100% auto no-repeat, cl(white);
    font-size: rem(20px);
    line-height: rem(30px);
    @include media-breakpoint-down(sm) {
      text-align: center;
      padding: rem(50px 0 130px 0);
    }
    h2{
      color: cl(primary);
      line-height: rem(40px);
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        margin-bottom: 2rem;
        text-align: center;
      }
    }
    p{
      margin-bottom: 0;
    }
    .kids-video-testim-block{
      display: flex;
      align-items: flex-start;
      justify-content: center;
      column-gap: rem(55px);
      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        column-gap: rem(25px);
      }
      .item{
        width: calc(25% - 2rem);
        @include media-breakpoint-down(sm) {
          width: calc(50% - 2rem);
          margin-bottom: .5rem;
        }
        @media (max-width: 414.98px) {
          width: 100%;
          margin-bottom: 2.5rem;
        }
        &:nth-child(even){
          padding-top: rem(60px);
          @include media-breakpoint-down(sm) {
            padding-top: rem(30px);
          }
          @media (max-width: 380.98px) {
            padding-top: 0;
          }
        }

        .video-holder{
          position: relative;
          width: 100%;
          padding-bottom: 110%;
          height: 0;
          display: block;
          z-index: 8;
          box-shadow: -5.209px 29.544px 68px 0px rgba(0, 0, 0, 0.21);
          overflow: hidden;
          cursor: pointer;
          @include media-breakpoint-down(md) {
          }
          video{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            @extend .pos-center;
            &[poster]{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          iframe{
            width: 100%!important;
            height: 100%!important;
            z-index: 0;
            position: absolute;
            @extend .pos-center;
          }
          &.active{
            .play{

              .listen{
                display: none;
              }
              .pause{
                display: block;
              }
            }
          }
          @include media-breakpoint-up(md) {
            &:hover{
              cursor: pointer;
              .play{
                top:0;
              }
            }
          }
          @include media-breakpoint-down(md) {
            &:active{
              .play {
                top: 0;
              }
            }
          }
          .play{
            background-size: cover;
            background-position: center bottom;
            background-repeat: no-repeat;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 100%;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            color: cl(white);
            align-items: center;
            padding: rem(5px 20px 10px 20px);
            .name{
              font-size: rem(20px);
              line-height: rem(34px);
              text-align: center;
            }
            .school{
              font-size: rem(16px);
              line-height: rem(26px);
              font-family: $font-r;
              margin-bottom: 0.8rem;
              text-align: center;
            }
            .listen{
              font-size: rem(16px);
              font-family: $font-r;
              text-transform: uppercase;
              display: block;
              text-align: center;
            }
            .pause{
              text-transform: uppercase;
              font-size: rem(16px);
              font-family: $font-r;
              display: none;
              text-align: center;
            }
          }
        }
      }
    }
  }
  &--news-section{
    padding: rem(100px 0 75px 0);
    position: relative;
    z-index: 1;
    background-color: cl(white);
    text-align: center;
    .home-news-slider{
      max-width: rem(1420px);
      margin: 0 auto;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        margin: 0 2rem 0 2rem;
        padding-bottom: 3rem;
      }
      .slick-prev,
      .slick-next{
        position: absolute;
        @extend .pos-centerY;
        border: 1px solid cl(primary);
        width: rem(50px);
        height: rem(50px);
        border-radius: 50%;
        cursor: pointer;
        @include media-breakpoint-down(sm) {
          top: unset;
          transform: unset;
          bottom: 0rem;
        }
        &:focus{
          outline: none;
        }

      }
      .slick-prev{
        left: rem(-70px);
        background-image: url("../img/prev-blue.png");
        background-size: 1.5rem 1.5rem;
        background-position: center;
        background-repeat: no-repeat;
        @include media-breakpoint-down(md) {
          left:  rem(-40px);
          background-size: 1rem 1rem;
        }
        @include media-breakpoint-down(sm) {
          left:  0;
          background-size: 1rem 1rem;
        }
      }
      .slick-next{
        right: rem(-70px);
        background-image: url("../img/next-blue.png");
        background-size: 1.5rem 1.5rem;
        background-position: center;
        background-repeat: no-repeat;
        @include media-breakpoint-down(md) {
          right: rem(-40px);
          background-size: 1rem 1rem;
        }
        @include media-breakpoint-down(sm) {
          right:  0;
          background-size: 1rem 1rem;
        }

      }
      .item{
        padding: rem(0 30px 0 70px);
        @include media-breakpoint-down(sm) {
          padding: rem(0 3px 30px 3px);
        }
        &-inner{
          border: 1px solid #1b3142;
          padding: rem(18px 27px 34px 0);
          display: flex;
          align-items: stretch;
          hyphens: auto;
          margin: 2.5rem auto 0;
          @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: flex-start;
            margin: 2.5rem auto 0;
            padding: rem(18px 0px 14px 0);
          }
          &:hover{
            .item-inner-content{
              .ttl{

              }
              .link-wrap{
                .link{
                  text-decoration: none;
                  &:before{
                    right: 0;
                  }
                }
              }
            }
          }
          .image{
            width: 100%;
            height: 100%;
            box-shadow: 1px 17px 24px 0px rgba(0, 0, 0, 0.1);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            &-wrap{
              border: rem(8px solid white);
              margin-left: rem(-49px);
              position: relative;
              margin-right: rem(28px);
              width: rem(226px);
              height: rem(226px);
              flex-shrink: 0;
              @include media-breakpoint-down(lg) {
                width: rem(186px);
                height: rem(186px);
              }
              @include media-breakpoint-down(md) {
                margin: -3rem auto 0;
              }
            }
          }
          &-content{
            text-align: left;
            padding-top: rem(8px);
            padding-bottom: rem(15px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            @include media-breakpoint-down(sm) {
              padding: rem(8px 15px);
            }
            .ttl{
              font-size: rem(22px);
              color: cl(primary);
              line-height:rem(30px);
              text-align: left;
              font-weight: 400;
              text-decoration: none;
              margin-bottom: rem(12px);
              display: block;
              cursor: pointer;
            }
            .text{
              font-family: $font-r;
              font-size: rem(16px);
              font-weight: 400;
              color:#424242;
              p{
                font-family: $font-r;
                font-size: rem(16px);
                font-weight: 400;
                color:#424242;
                margin-bottom: 0;
              }
            }
            .link-wrap{
              margin-top: rem(5px);
              .link{
                font-size: rem(16px);
                color: cl(primary);
                line-height:rem(30px);
                text-align: left;
                font-weight: 400;
                position: relative;
                padding-right: rem(38px);
                cursor: pointer;
                &:before{
                  content: "";
                  position: absolute;
                  @extend .pos-centerY;
                  right: rem(16px);
                  background-image: url("../img/svg/arrow.svg");
                  background-position: left center;
                  background-size: contain;
                  width: rem(20px);
                  height: rem(10px);
                  transition: 0.3s ease-in-out;
                }
              }
            }
          }
        }
      }
    }
    .btn-wrap{
      text-align: center;
      margin: 2.5rem auto 0;
      @include media-breakpoint-down(sm) {
        margin: -3rem auto 0;
      }
      .btn{
        margin: 0 auto;
      }
    }
  }
  &--leaders-section{
    position: relative;
    z-index: 1;
    padding: rem(70px 0 195px 0);
    background:center/cover no-repeat, cl(primary);
    background-attachment: fixed;
    color: cl(white);
    text-align: left;
    font-size: rem(20px);
    font-family: $font-r;
    line-height: rem(30px);
    letter-spacing: 0.2em;
    @include media-breakpoint-down(lg) {
      background-attachment: scroll;
    }
    @include media-breakpoint-down(md) {
      background-attachment: scroll;
      padding: rem(80px 0 130px 0);
      font-size: rem(20px);
    }
    h2{
      margin-bottom: 2rem;
      font-weight: 600;
      text-transform: capitalize;
    }
    p{
      margin-bottom:1.5rem;
    }
    .image-side{
      margin-left: 10vw;
      width: 33vw;
      height: 19.1vw;
      position: relative;
      z-index: 2;
      @include media-breakpoint-down(md) {
        width: 88vw;
        height: 45vw;
        margin: 2rem auto 2rem;
      }
      .logo{
        @extend .pos-center;
        width: 19.6%;
      }
      .block{
        width: 33.2%;
        height: 50%;
        position: absolute;
        padding-top: 7.2%;
        @include media-breakpoint-down(md) {
          width: 36.2%;
        }
        &.left-top{
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
        }
        &.left-bottom{
          bottom: 0;
          left: 0;
        }
        &.right-top{
          top: 0;
          right: 0;
        }
        &.right-bottom{
          bottom: 0;
          right: 0;
        }
        .number{
          font-family: $font;
          font-weight: 600;
          font-size: rem(60px);
          line-height: rem(60px);
          letter-spacing: 0.1em;
          margin-bottom: 1rem;
          @include media-breakpoint-down(md) {
            font-size: 6vw;
            line-height: 7vw;
            margin-bottom: 0.5rem;
          }
        }
        .name{
          font-family: $font-r;
          font-weight: 400;
          font-size: rem(20px);
          line-height: rem(30px);
          letter-spacing: 0.1em;
          @include media-breakpoint-down(md) {
            font-size: 2.5vw;
            line-height: 2.5vw;
          }
        }
      }
      .line-wrap{
        position: absolute;
        overflow: hidden;
        &-left{
          left: 0;
          @extend .pos-centerY;
          height: 1px;
          width: 33.2%;
          @include media-breakpoint-down(md) {
            width: 36.2%;
          }
        }
        &-right{
          right: 0;
          @extend .pos-centerY;
          height: 1px;
          width: 33.2%;
          @include media-breakpoint-down(md) {
            width: 36.2%;
          }
        }
        &-top{
          top: 0;
          @extend .pos-centerX;
          width: 1px;
          height: 27%;
          @include media-breakpoint-down(md) {
            height: 24%;
          }
        }
        &-bottom{
          bottom: 0;
          @extend .pos-centerX;
          width: 1px;
          height: 27%;
          @include media-breakpoint-down(md) {
            height: 24%;
          }
        }
        .line{
          background-color: cl(white);
          width: 100%;
          height: 100%;

        }

      }
    }
    .letter-decor{
      font-family: $font;
      font-size: 10.4vw;
      line-height: 10.4vw;
      position: absolute;
      //width: 100%;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 3rem;
      width: 100%;
      left: 0;
      bottom: -1.2vw;
      color: rgba(255, 255, 255, 0);
      text-stroke: 1px #fff;
      -webkit-text-fill-color: rgba(255, 255, 255, 0); /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #fff;
      overflow: hidden;
      z-index: 0;
      @include media-breakpoint-down(md) {
        font-size: 9.2vw;
        line-height: 9.2vw;
        column-gap: 1.5rem;
        bottom: -1.1vw;
      }
      &.decor-top-right{
        top: -6%;
        right: 1%;
        @include media-breakpoint-down(md) {
          top:-1%;
          right: 4%;
        }
      }
      &.decor-bottom-left{
        left: -25%;
        bottom: 0vw;
        @include media-breakpoint-down(md) {
          left: -9%;
          bottom: 1%;
        }
      }
      .word{
        display: flex;
      }


    }
  }
}

.stay-in-touch-section{
  position: relative;
  z-index: 1;
  padding: rem(80px 0 200px 0);
  background:center/cover no-repeat, cl(primary);
  background-attachment: fixed;
  color: cl(white);
  text-align: center;
  font-size: rem(24px);
  font-family: $font-r;
  line-height: rem(34px);
  letter-spacing: 0.02em;
  @include media-breakpoint-down(lg) {
    background-attachment: scroll;
  }
  @include media-breakpoint-down(md) {
    background-attachment: scroll;
    padding: rem(80px 0 130px 0);
    font-size: rem(20px);
  }
  .btn{
    min-width: rem(220px);
    margin: 0 auto;
  }
  .logo-deco{
    .pic{
      width: rem(257px);
      margin-bottom: 1.25rem;
    }
  }
  h2{
    margin-bottom: 2rem;
    font-weight: 500;
    font-family: $font;
  }
  p{
    margin-bottom:1.5rem;
  }
  .letter-decor{
    font-family: $font;
    font-size: 6.15vw;
    line-height: 6.15vw;
    letter-spacing: 0.1em;
    color: cl(white);
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -0.93vw;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3rem;
    @include media-breakpoint-down(lg) {
      font-size: 5.7vw;
      bottom: -0.93vw;
      column-gap: 1rem;
    }
    .word{
      display: flex;
    }
  }
}