.board-minutes-main{
  padding: rem(60px 0);
  .files-block{
    max-width: rem(910px);
    margin: 0 auto;
    .item-download-file{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #d1d6d9;
      padding: rem(20px 23px);
      background-color: cl(white);
      margin-bottom: rem(12px);
      transition: 0.2s ease-in-out;
      font-size: rem(24px);
      font-family: $font;
      line-height: rem(35px);
      @include media-breakpoint-down(md) {
        font-size: rem(16px);
        line-height: rem(26px);
        padding: rem(15px 15px);
      }
      &:hover{
        background-color: cl(primary);
        border: 1px solid cl(primary);
        text-decoration: none;
        .name{
          color: cl(white);
        }
        .download-pic{
          &-main{
            opacity: 0;
          }
          &-hover{
            opacity: 1;
          }
        }
      }
      .text-cont{
        overflow-x: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: calc(100% - 3rem);
      }
      .file-img{
        height: rem(35px);
        margin-right: rem(25px);
        @include media-breakpoint-down(md) {
          height: rem(25px);
          margin-right: rem(15px);
        }
      }
      .name{
        color: cl(primary);

        white-space: nowrap;

        transition: 0.2s ease-in-out;
      }
      .download-pic{
        position: relative;
        width: rem(25px);
        height: rem(25px);
        @include media-breakpoint-down(md) {
          width: rem(18px);
          height: rem(18px);
        }
        &-main{
          opacity: 1;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: contain;
          width: 100%;
          height: 100%;
          transition: 0.2s ease-in-out;
        }
        &-hover{
          transition: 0.3s ease-in-out;
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.producers-section{
  padding: rem(65px);
  .producers-section-inner{
    max-width: rem(1065px);
    margin: 0 auto;
    font-family: $font-r;
    color: cl(text-gray);
    font-size: rem(18px);
    line-height: rem(28px);
    h2,h3,h4{
      color: cl(primary);
      font-family: $font;
    }
    p{
      margin-bottom: 1.5rem;
    }
    a{
      color: cl(text-gray);
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
    ol,ul{
      margin-bottom: 1.5rem;
      padding-left: 2.5rem;
      li{
        font-family: $font-r;
        color: cl(text-gray);
        font-size: rem(18px);
        line-height: rem(28px);
        margin-bottom: 1rem;
      }
    }

  }

}

.faq-section{
  padding: rem(75px 0 80px 0);
  position: relative;

  .accordion-wrap{
    position: relative;
  }
  .faq-accordion{
    z-index: 5;
    position: relative;
  }
  .card {
    background: none;
    position: relative;
    margin: rem(0 0 13px);
    font-family: $font;
    border: 0;
    border-radius: 0;

    .card-header {
      background: none;
      padding: 0;

      border-bottom: 0;
      .head-link {
        border: 1px solid cl(primary);
        border-bottom: 0;

        &.collapsed {
          border: 1px solid #d1d6d9;
        }
      }
      button {
        font-size: rem(24px);
        line-height: rem(34px);
        color:cl(primary);
        font-weight: 400;
        background: cl(white);
        width:100%;
        display: block;
        border:0;
        padding: rem(20px 80px 20px 80px);
        position: relative;
        text-align: left;
        font-family: $font;
        @include media-breakpoint-down(sm) {
          font-size: rem(20px);
          line-height: rem(26px);
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(18px);
          line-height: rem(26px);
          padding: rem(14px 65px 14px 80px);
        }
        &:focus {
          outline: none;
        }

        &.collapsed {
          .plus-minus{

            &:after{
              opacity: 1;
            }
          }

        }
        @include media-breakpoint-down(xs) {
          font-size: rem(20px);
          line-height: rem(30px);
        }
        .plus-minus{
          position: absolute;
          right:rem(30px);
          top: 50%;
          transform: translateY(-50%) rotate(-180deg);
          transition: all .3s ease;
          width: rem(30px);
          height: rem(30px);
          @include media-breakpoint-down(xs) {
            width: rem(20px);
            height: rem(20px);
            right:rem(20px);
          }
          &:before{
            content: "";
            position: absolute;
            width: 100%;
            height: rem(2px);
            @extend .pos-centerY;
            left: 0;
            background-color: cl(primary);
          }
          &:after{
            content: "";
            position: absolute;
            height: 100%;
            width: rem(2px);
            @extend .pos-centerX;
            background-color: cl(primary);
            top: 0;
            opacity: 0;
            transition: 0.3s ease-in-out;
          }
        }
      }
      .logo{
        position: absolute;
        left:rem(20px);
        @extend .pos-centerY;
        transition: all .3s ease;
        z-index: 2;
        width:rem(36px);
        height: rem(36px);
        object-fit: cover;
        border-radius: 50%;
        @include media-breakpoint-down(xs) {
          width:rem(34px);
          height: rem(34px);
        }
      }
    }
    .collapse-body {
      border: 1px solid cl(primary);
      border-top: 0;

    }
    .card-body {
      background-color: cl(white);
      padding: rem(20px 46px 20px 80px);
      font-size: rem(18px);
      line-height: rem(28px);
      border:0;
      border-radius: 0;
      font-family: $font-r;
      color: cl(text-gray);
      @include media-breakpoint-down(xs) {
        padding: rem(10px 26px 20px 20px);
      }
      p {
        margin: 0 0 1.5rem;
        font-size: rem(18px);
        line-height: rem(28px);
        @include media-breakpoint-down(xs) {
          font-size: rem(16px);
          line-height: rem(26px);
        }
      }
      &:focus {
        outline: none;
      }
      div{
        &:focus {
          outline: none;
        }
      }
      ul,ol{
        padding-left: 2rem;
      }

    }
  }
}

.core-beliefs-section{
  padding: rem(40px 0 75px 0);
  .btn{
    min-width: rem(180px);
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }

  .beliefs-list {
    margin-bottom: 1rem;
    .item {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: rem(18px);
      line-height: rem(28px);
      font-family: $font-r;
      color: cl(text-gray);
      position: relative;
      &:first-child {
        padding: 0;
      }
      &:before {
        content: '';
        position: absolute;
        left: rem(45px);
        top:0;
        height: 100%;
        background: cl(primary);
        width: 1px;
        @include media-breakpoint-down(xs) {
        display: none;
        }
      }
      @include media-breakpoint-down(xs) {
      flex-direction: column;
      align-items: center;
      padding: rem(20px 0 0 0);
    }
      .avatar {
        border-style: solid;
        border-width: rem(10px);
        border-color: rgb(255, 255, 255);
        border-radius: 50%;
        background-color: rgb(25, 48, 66);
        width: rem(90px);
        height: rem(90px);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-shrink: 0;
        z-index: 3;
        margin-right: rem(14px);
        @include media-breakpoint-down(xs) {
          margin: 0 auto 0.5rem;
        }
        img{
          width: 2.75rem;
          height: 2.75rem;
          object-fit: contain;
        }
      }
      .name{
        font-size: rem(24px);
        line-height: rem(34px);
        font-family: $font;
        color: cl(text);
        margin-bottom: 1rem;
        @include media-breakpoint-down(xs) {
          text-align: center;
        }
      }
      .hold{
        padding-top: rem(35px);
        @include media-breakpoint-down(xs) {
         padding-top: 0;
        }
        ul{
          list-style: none;
          position: relative;
          z-index: 3;
          margin-bottom: 1.5rem;
          li{
            position: relative;
            padding: rem(2px 0 2px 15px);
            &:after{
              content: '';
              position: absolute;
              top:rem(12px);
              left: 0;
              width: rem(7px);
              height: rem(7px);
              border-radius: 50%;
              line-height: rem(20px);
              background-color: cl(primary);
            }
          }
        }
      }
    }
  }
}

.best-practices-section{
  padding: rem(60px 0 50px 0);
  font-size: rem(18px);
  line-height: rem(28px);
  font-family: $font-r;
  color: cl(text-gray);
  p{
    margin-bottom: 1.5rem;
  }
  ul{
    list-style: none;
    position: relative;
    z-index: 3;
    margin-bottom: 1.5rem;
    padding-left: 2rem;
    li{
      position: relative;
      padding: rem(2px 0 2px 15px);
      &:after{
        content: '';
        position: absolute;
        top:rem(12px);
        left: 0;
        width: rem(7px);
        height: rem(7px);
        border-radius: 50%;
        line-height: rem(20px);
        background-color: cl(primary);
      }
    }
  }
  .image-side-wrap{
    position: relative;
    z-index: 2;
    width: 40vw;
    height: 32.3vw;
    @include media-breakpoint-down(md) {
      width: 95vw;
      margin-bottom: 24vw;
      height: 76.7vw;
    }
    .spica{
      position: absolute;
      right: 21%;
      top: -2.5vw;
      width: 15vw;
      z-index: 0;
      @include media-breakpoint-down(md) {
        right: 2vw;
        top: -2.5vw;
        width: 41vw;
      }
    }
    .letter-decor{
      font-family: $font;
      font-size: 7.2vw;
      line-height: 7vw;
      position: absolute;
      padding-left: 2rem;
      padding-bottom: 0.5rem;
      z-index: 1;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      color: rgba(255, 255, 255, 1);
      text-align: right;
      text-shadow: -10.625px 2.847px 16px rgba(0, 0, 0, 0.15);
      overflow: hidden;
      right: 0;
      bottom: -0.5vw;
      @include media-breakpoint-down(md) {
       bottom: -18vw;
        font-size: 13.2vw;
        line-height: 13vw;
        right: 2vw;
      }
      .word{
        display: flex;
        &.first-line{
          font-size: 6.25vw;
          line-height: 6vw;
          @include media-breakpoint-down(md) {
            font-size: 11.25vw;
            line-height: 11vw;
          }
        }
      }
    }
  }
  .image-side {
    position: relative;
    z-index: 2;
    width: 64%;
    height: 68.11%;
    @include media-breakpoint-down(md) {
      width: 88%;
      height: 93.95%;
    }
    .img-box{
      position: absolute;
      box-shadow: -5.209px 29.544px 68px 0px rgba(0, 0, 0, 0.21);
      z-index: 1;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      padding-left: 7%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      &.left-top-img{
        left: 0;
        top: 0;
        width: 35.79%;
        height: 36.73%;
      }
      &.right-top-img{
        width: 46.5%;
        height: 36.2%;
        right: 14%;
        top:23.95%;
      }
      &.left-btn-img{
        top: 41.73%;
        left: 0;
        width: 35.79%;
        height: 36.73%;

      }
      &.right-btn-img{
        bottom: 0;
        width: 61%;
        height: 36%;
        right: 0;
      }
      .number{
        font-family: $font;
        font-weight: 600;
        font-size: 3vw;
        line-height: 3vw;
        letter-spacing: 0.1em;
        margin-bottom: 1rem;
        color: cl(white);
        @include media-breakpoint-down(md) {
          font-size: 7vw;
          line-height: 7vw;
        }

      }
      .name{
        font-family: $font-r;
        font-weight: 400;
        font-size: rem(20px);
        line-height: rem(30px);
        letter-spacing: 0.1em;
        color: cl(white);
        text-transform: uppercase;

      }
    }
    .book{
      position: absolute;
      left: 52%;
      width: 19.4%;
      top: 2%;
    }
    .keys{
      position: absolute;
      left: 10%;
      width: 14%;
      bottom: -8%;
    }

  }
}

.history-section{
  padding: rem(70px 0 110px 0);
  font-size: rem(18px);
  line-height: rem(28px);
  font-family: $font-r;
  color: cl(text-gray);
  background:center/cover no-repeat, cl(white);
  @include media-breakpoint-down(md) {
    padding: rem(70px 0 70px 0);
  }
  .text-scroll{
    height: rem(235px);
    overflow-y: auto;
    hyphens: auto;
    @include media-breakpoint-down(md) {
      height: auto;
    }
  }
  p{
    margin-bottom: 1.5rem;
  }

  .image-side {
    position: relative;
    z-index: 2;
    width: 43.9vw;
    height: 29.12vw;
    float: right;
    @include media-breakpoint-down(md) {
      width: 80vw;
      float: unset;
      margin:0 auto 14vw;
      height: 53.06vw;
    }
    &:after{
      content: "";
      clear: both;
    }
    .img-box{
      position: absolute;
      box-shadow: -5.209px 29.544px 68px 0px rgba(0, 0, 0, 0.48);
      height: 48.2%;
      z-index: 3;
      width: 32%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &.left-img{
        left: 0;
        @extend .pos-centerY;
      }
      &.right-img{
        right: 0;
        @extend .pos-centerY;
      }
      &.center-btn-img{
        bottom: 0;
        @extend .pos-centerX;
      }
      &.center-top-img{
        top: 0;
        @extend .pos-centerX;
      }
    }
    .book{
      position: absolute;
      right: 9%;
      width: 15.4%;
      top: 5%;
    }
    .keys{
      position: absolute;
      left: 10%;
      width: 9.6%;
      bottom:0;
    }
    .letter-decor{
      font-family: $font;
      font-size: 10.4vw;
      line-height: 10vw;
      position: absolute;
      padding-left: 2rem;
      padding-bottom: 0.5rem;
      z-index: 1;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      color: rgba(255, 255, 255, 1);
      text-align: right;
      text-shadow: -10.625px 2.847px 16px rgba(0, 0, 0, 0.15);
      overflow: hidden;

      &.decor-top-left{
        left: -1vw;
        top: 2vw;
        @include media-breakpoint-down(xs) {
          left: -4vw;
          top: 4vw;
        }
      }
      &.decor-bottom-right{
        left: 63%;
        bottom: 0;

        @include media-breakpoint-down(md) {
          font-size: 12vw;
          line-height: 12vw;
          left: unset;

          right: -2rem;
          bottom: -10vw;
        }
      }
      .word{
        display: flex;
        &.first-line{
          font-size: 6.25vw;
          line-height: 6vw;
          @include media-breakpoint-down(md) {
            font-size: 11.25vw;
            line-height: 11vw;
          }
        }
      }
    }
  }
}

.form-section{
  padding: rem(70px 0 50px 0);
  font-size: rem(18px);
  line-height: rem(28px);
  font-family: $font-r;
  color: cl(text-gray);
  background:center/cover no-repeat, cl(white);

}
.meetings-section{
  padding: rem(30px 0 90px 0);
  font-size: rem(18px);
  line-height: rem(28px);
  font-family: $font-r;
  color: cl(text-gray);
  ul,ol{
    padding-left: 2rem;
  }
  a{
    color: cl(text-gray);
  }
  .meetings-grid{
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

  }
  .meeting-item{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d1d6d9;
    padding: rem(15px 23px);
    background-color: cl(white);
    margin-bottom: rem(12px);
    transition: 0.2s ease-in-out;
    font-size: rem(24px);
    font-family: $font;
    line-height: rem(35px);
    @include media-breakpoint-down(md) {
      font-size: rem(18px);
      line-height: rem(26px);
      padding: rem(15px 15px);
    }
    &:hover{
      background-color: cl(primary);
      color: cl(white);
    }
  }
}
