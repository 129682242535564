/* banner start */
.banner {
  position: relative;
  background: center/cover no-repeat, cl(primary, 0.5);
  color: cl(white);
  &--home {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &--page {
    height: rem(400px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: rem(30px 0 30px 0);
    background:center/cover no-repeat, cl(primary);
    background-attachment: fixed;
    color: cl(white);
    margin-top: rem(130px);
    @include media-breakpoint-down(lg){
      margin-top: rem(120px);
      background-attachment: scroll;
    }
    @include media-breakpoint-down(md) {
      margin-top: rem(160px);
    }
    @include media-breakpoint-down(sm) {
      margin-top: rem(130px);
      height: rem(350px);
    }
    @include media-breakpoint-down(xs) {
      margin-top: rem(108px);
    }
    .container {
      position: relative;
      z-index: 2;
    }
    &-small{
      height: rem(200px);
    }

    h1 {
      font-size: rem(70px);
      font-family: $font;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      line-height: 1;
      text-align: center;
      text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.36);
      @include media-breakpoint-down(md) {
        font-size: rem(50px);
      }
      @include media-breakpoint-down(sm) {
        font-size: rem(40px);
      }
    }
    .page-ttl{
      font-size: rem(70px);
      font-family: $font;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      line-height: 1;
      text-align: center;
      text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.36);
      max-width: rem(1000px);
      @include media-breakpoint-down(md) {
        font-size: rem(50px);
      }
      @include media-breakpoint-down(sm) {
        font-size: rem(40px);
      }
      @include media-breakpoint-down(xs) {
        font-size: rem(35px);
      }
    }

  }
  &-video {

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    //position: fixed;
    position: absolute;
    z-index: 0;
    &.banner-video-absolute{
      position: absolute;
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-text {
    position: relative;
    width:100%;
    height: 100%;
    color:cl(white);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    .ttl {
      font-size: rem(70px);
      font-family: $font;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      line-height: 1;
      text-align: center;
      text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.36);
    }
    p {
      margin: 0;
    }
  }
  &--tabs{
    position: relative;
    z-index: 23;
    text-align: center;
    color: cl(white);
    &:before{
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      opacity: 1;
      height: 110%;
      background: url(../img/shadow-2.png);
      background-size: 100vw 100%;
      background-position: bottom;
      -webkit-transition: 350ms ease-in-out;
      -moz-transition: 350ms ease-in-out;
      transition: 350ms ease-in-out;
    }
    .tabs-info{
      position: relative;
      z-index: 1;
      .nav-wrap{
        display: flex;
        justify-content: center;
        align-items: center;

        .nav{
          &.nav-tabs{
            border: 1px solid white;
            border-radius: rem(30px);
            padding:rem(4px);
            column-gap: rem(15px);
            @include media-breakpoint-down(xs) {
              flex-wrap: wrap;
              column-gap: 0;
              row-gap: 1rem;
              justify-content: space-between;
              border: 0;
            }
            .nav-link{
              border-radius: rem(25px);
              background-color: transparent;
              border: 1px solid transparent;
              font-size: rem(18px);
              white-space: nowrap;
              color: cl(white);
              padding: rem(9px 21px);
              line-height: rem(30px);
              font-weight: 600;
              min-width: rem(145px);
              letter-spacing: 0.1em;
              @include media-breakpoint-down(xs) {
                width: 48%;
                border: 1px solid cl(white);
              }
              &.active{
                background-color: cl(white);
                color: cl(primary);
              }
              &:hover{
                color: cl(primary);
              }
              &:focus{
                outline: none;
              }
            }
          }

        }
      }
      h2{
        font-weight: 600;
      }
      .schools-slider{
        margin: rem(30px -30px 10px -30px);
        @include media-breakpoint-down(lg) {
          margin: rem(30px 40px 10px 40px);
        }
        .item{
          padding: rem(0 35px);
          @include media-breakpoint-down(xs) {
            padding: rem(0 10px);
          }
          .item-inner{
            display: flex;
            flex-direction: column;
            align-items: center;
            &:hover{
              .img-holder{
                .image{
                  opacity: 0;
                }
                .view{
                   display: flex;
                }
              }
              .text-content{
                .name{
                  opacity: 0;
                }
                .address{
                  opacity: 1;
                }
              }
            }
            .img-holder{
              width: rem(160px);
              height: rem(160px);
              object-fit: contain;
              position: relative;
              font-size: rem(18px);
              line-height: rem(26px);
              margin-bottom: rem(10px);
              .image{
                width: 100%;
                object-fit: contain;
                object-position: center;
                transition: all 0.2s linear;
              }
              .view{
                position: absolute;
                @extend .pos-center;
                width: rem(160px);
                height: rem(160px);
                border-radius: 50%;
                background-color: cl(white,0.96);
                justify-content: center;
                align-items: center;
                flex-direction: column;
                overflow: hidden;
                display: none;
                color: cl(primary);
                font-weight: 500;
                transition: all 0.2s linear;
                .eye{
                  width: rem(40px);
                  margin: 0 auto 0.5rem;
                }
                &-link{
                  text-align: center;
                }
              }
            }
            .text-content{
              min-height: rem(95px);
              position: relative;
              width: 100%;
              text-align: center;
              .name{
                opacity: 1;
                font-family: $font;
                font-weight: 600;
                font-size: rem(20px);
                line-height:rem(30px);
                letter-spacing: 0.1em;
                transition: all 0.2s linear;
              }
              .address{
                opacity: 0;
                top: 0;
                left: 0;
                width: 100%;
                position: absolute;
                font-family: $font-r;
                font-size: rem(16px);
                line-height:rem(26px);
                transition: all 0.2s linear;

              }
            }
          }
        }
        .slick-prev,
        .slick-next{
          cursor: pointer;
          position: absolute;
          top: rem(65px);
          border: 0;
          width: rem(40px);
          height: rem(40px);
          background-color: transparent;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          @include media-breakpoint-down(xs) {
            width: rem(30px);
            height: rem(30px);
          }
          &:focus{
            outline: none;
          }

        }
        .slick-prev{
          left: rem(-30px);
          background-image: url("../img/svg/prev.svg");
          @include media-breakpoint-down(md) {
            left:  rem(-40px);
          }
        }
        .slick-next{
          right: rem(-30px);
          background-image: url("../img/svg/next.svg");
          @include media-breakpoint-down(md) {
            right: rem(-40px);
          }
          @include media-breakpoint-down(xs) {
            width: rem(30px);
            height: rem(30px);
          }
        }
      }
    }

  }
}
/* banner end */

