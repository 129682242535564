.header {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	z-index: 1000;
	//background: transparent;
	//padding: rem(8px 35px);
	transition: all .3s linear;
	font-family: $font-r;
	font-weight: 500;
	background-color: cl(white,1);
	padding: rem(10px 35px);
	@include media-breakpoint-down(lg){
		padding-top: rem(6px);
		padding-bottom: rem(6px);
	}
	@include media-breakpoint-down(xs) {
		//height: 4.75rem;
		padding: rem(5px 20px 5px 20px);
		background-color: cl(white);
		align-items: center;
	}
	&.header--home{
		background: transparent;
		padding: rem(8px 35px);
		@include media-breakpoint-down(sm) {
			padding-top: rem(4px);
			padding-bottom: rem(4px);
		}
		@include media-breakpoint-down(lg){
			padding: rem(13px 40px);
		}
		@include media-breakpoint-down(sm) {
			padding: rem(13px 30px);
		}
		@include media-breakpoint-down(xs) {
			//height: 4.75rem;
			padding: rem(5px 20px 5px 20px);
			background-color: cl(white);
			align-items: center;
		}
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			opacity: 1;
			height: 250px;
			background: url(../img/shadow.png) repeat-x;
			-webkit-transition: 350ms ease-in-out;
			-moz-transition: 350ms ease-in-out;
			transition: 350ms ease-in-out;
			@include media-breakpoint-down(sm) {
				top: -80%;
			}

		}
		.header-logo {
			flex-shrink: 0;
			position: relative;
			display: flex;
			align-items: center;
			flex-wrap: nowrap;
			color: cl(primary);
			font-size: rem(40px);
			font-family: $font;
			font-weight: 400;
			@extend .ttu;
			margin-top: -1.5rem;
			white-space: nowrap;
			transition: all 0.3s linear;
			@include media-breakpoint-down(lg) {
				font-size: rem(28px);
			}
			@include media-breakpoint-down(md) {
				margin-top: 0.5rem;
			}
			@include media-breakpoint-down(sm) {
				font-size: rem(18px);
			}
			@include media-breakpoint-down(xs) {
				font-size: rem(16px);
			}
			&:hover{
				text-decoration: none;
				color: cl(primary);
			}
			img {
				max-width: 100%;
				height: auto;
				width:rem(170px);
				transition: all 0.3s linear;
				@include media-breakpoint-down(lg) {
					width: rem(110px);
				}
				@include media-breakpoint-down(sm) {
					width:rem(90px);
				}
				@include media-breakpoint-down(xs) {
					width:rem(65px);
				}
			}
			span{
				margin-bottom: 7%;
				margin-left: 0.5rem;
				@include media-breakpoint-down(lg) {
					margin-bottom: 0;
				}
			}
		}
	}
	&.header--inner-pages{
		background-color: cl(white,1);
		padding: rem(10px 35px);
		@include media-breakpoint-down(lg){
			padding-top: rem(6px);
			padding-bottom: rem(6px);
		}
		@include media-breakpoint-down(sm) {
			padding-top: rem(4px);
			padding-bottom: rem(4px);
		}
		.header-inner {
			margin-bottom: 0;

		}
		.header-logo{
			font-size: rem(30px);
			@include media-breakpoint-down(sm) {
				font-size: rem(18px);
			}
			@include media-breakpoint-down(xs) {
				font-size: rem(16px);
			}
			img {
				width: rem(107px);
				@include media-breakpoint-down(sm) {
					width:rem(90px);
				}
				@include media-breakpoint-down(xs) {
					width: rem(65px);
				}
			}
			span{
				margin-bottom: 0;
			}

		}

		.header--inner-block-bottom{
			align-items: center;
		}
		&:before {
			display: none;
		}
	}
	&.header-scroll{
		background-color: cl(white,1);
		padding-top: rem(6px);
		padding-bottom: rem(6px);
		box-shadow: rem(5px 0 10px rgba(0,0,0,0.2));
		@include media-breakpoint-down(lg){
			padding-top: rem(4px);
			padding-bottom: rem(4px);
		}
		@include media-breakpoint-down(sm) {
			padding-top: rem(4px);
			padding-bottom: rem(4px);
		}
		.header-inner {
			margin-bottom: 0;

		}
		.header-logo{
			font-size: rem(30px);
			@include media-breakpoint-down(sm) {
				font-size: rem(18px);
			}
			@include media-breakpoint-down(xs) {
				font-size: rem(16px);
			}
			img {
				width: rem(107px);
				@include media-breakpoint-down(sm) {
					width:rem(90px);
				}
				@include media-breakpoint-down(xs) {
					width: rem(65px);
				}
			}
			span{
				margin-bottom: 0;
			}

		}

		.header--inner-block-bottom{
			align-items: center;
		}
		&:before {
			display: none;
		}
	}
	&-logo {
		flex-shrink: 0;
		position: relative;
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		color: cl(primary);
		font-size: rem(40px);
		font-family: $font;
		font-weight: 400;
		@extend .ttu;
		margin-top: -1.5rem;
		white-space: nowrap;
		transition: all 0.3s linear;
		@include media-breakpoint-down(lg) {
			font-size: rem(28px);
		}
		@include media-breakpoint-down(md) {
			margin-top: 0.5rem;
		}
		@include media-breakpoint-down(sm) {
			font-size: rem(18px);
		}
		@include media-breakpoint-down(xs) {
			font-size: rem(16px);
		}
		&:hover{
			text-decoration: none;
			color: cl(primary);
		}
		img {
			max-width: 100%;
			height: auto;
			width:rem(170px);
			transition: all 0.3s linear;
			@include media-breakpoint-down(lg) {
				width: rem(110px);
			}
			@include media-breakpoint-down(sm) {
				width:rem(90px);
			}
			@include media-breakpoint-down(xs) {
				width:rem(65px);
			}
		}
		span{
			margin-bottom: 7%;
			margin-left: 0.5rem;
			@include media-breakpoint-down(lg) {
				margin-bottom: 0;
			}
		}
	}
	&-inner {
		margin-bottom: 4.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width:100%;
		@include media-breakpoint-down(lg) {
			justify-content: flex-end;
			margin-bottom: 0;
		}

	}
	&-nav {
		width:100%;
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		z-index: 2;
		@include media-breakpoint-down(lg) {
			position: fixed;
			z-index: 200;
			right:-100%;
			top:0;
			height: 100%;
			margin: 0;
			width:30rem;
			padding: rem(210px 0 0);
			background: cl(primary);
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			transition: all 0.3s linear;
			&.show {
				right:0;
				box-shadow: rem(5px 0 10px rgba(0,0,0,0.3));
			}
		}
		@include media-breakpoint-down(sm) {
			width:100%;
		}
		&-logo {
			display: none;
			position: absolute;
			top:1rem;
			@extend .pos-centerX;
			@include media-breakpoint-down(lg) {
				display: block;
				width: rem(150px);
			}
		}
		& > ul {
			display: flex;
			align-items: center;
			list-style: none;
			margin: 0;
			@include media-breakpoint-down(lg) {
				height: 100%;
				width:100%;
				overflow: auto;
				display: block;
			}
			& > li {
				position: relative;
				margin-left: rem(25px);
				padding: 0;
				&:before{
					display: none;
				}
				@include media-breakpoint-down(lg) {
					padding: 0;
					margin: 0;
					width:100%;

				}
				& > a {
					display: block;
					font-size: rem(16px);
					line-height: rem(18px);
					font-family: $font-r;
					font-weight: 700;
					color:cl(primary);
					position: relative;
					@include media-breakpoint-down(lg) {
						display: block;
						padding: 1rem 2rem;
						font-size: rem(22px);
						line-height: rem(22px);
						text-align: center;
						color:cl(white);
					}
					&:hover {
						text-decoration: none;
						//color:cl(primary);
						&:before{
							width: 100%;
						}
					}
					i{
						font-size: rem(13px);
					}
					&:before{
						content: "";
						@extend .pos-centerX;
						bottom: -2px;
						width: 0;
						height: 2px;
						transition: all 0.3s linear;
						background-color: cl(primary);
					}

				}
				&.active {
					& > a {
						text-decoration: none;
						color:cl(primary);
					}
				}
				&:hover{
					.drop {
						display: block;
						//@include media-breakpoint-down(lg) {
						//	display: none;
						//}
					}
				}
				.drop {
					position: absolute;
					top:rem(17px);
					padding-top: rem(21px);
					left:0;
					//width:rem(300px);
					width: max-content;
					min-width: rem(220px);
					display: none;
					@include media-breakpoint-down(lg) {
						display: block;
						position: static;
						width:100%;
						min-width: 100%;
						padding: 0;
						margin: 0;
					}
					ul {
						margin: 0;
						background: cl(white);
						padding: rem(2px);
						list-style: none;
						box-shadow:rem(1px 1px 6px cl(black, .21));
						@include media-breakpoint-down(lg) {
							box-shadow: none;
							padding: 0;
							background: none;
						}
						li {
							padding: 0;
							&:before{
								display: none;
							}
							a {
								display: block;
								font-size: rem(16px);
								line-height: rem(22px);
								font-weight: 400;
								font-family: $font-r;
								color:cl(primary);
								padding: rem(10px 20px 10px 20px);
								text-transform: none;
								@include media-breakpoint-down(lg) {
									display: block;
									padding: 1rem 2rem;
									font-size: rem(20px);
									line-height: rem(22px);
									text-align: center;
									color:cl(white);
								}
								&:hover {
									text-decoration: none;
									background: cl(primary);
									color: cl(white);
									@include media-breakpoint-down(lg) {
										text-decoration: none;
										background: transparent;
										color: cl(white);
									}
								}
							}
							&.active {
								a {
									color: cl(white);
									text-decoration: none;
									background: cl(primary);
									@include media-breakpoint-down(lg) {
										text-decoration: none;
										background: transparent;
										color: cl(primary);
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&-buttons {
		position: relative;
		display: flex;
		align-items: center;
		margin: rem(0 0 0 22px);
		z-index: 1;
		@include media-breakpoint-down(md) {
			flex-direction: column;
			width: 100%;
			margin-left: 0;
			padding: 1rem;
			background-color: cl(primary);
		}
		.btn{
			margin-left: rem(15px);

			@include media-breakpoint-down(md) {
				width: 100%;
				margin-left: 0;
				margin-bottom: 1rem;
			}
		}

	}
	&--inner-block{
		position: relative;
		z-index: 3;

		&-top{
			position: relative;
			color: cl(primary);
			font-family: $font-r;
			font-weight: 400;
			display: flex;
			justify-content: space-between;
			//@include media-breakpoint-down(sm) {
			//	margin: 0 -1.25rem;
			//}
			.colum{
				display: flex;
				align-items: center;
				&:first-child{
					width: rem(200px);
					@include media-breakpoint-down(md) {
						display: none;
					}
				}
				&:nth-child(2){
					justify-content: center;
					width: calc(100% - 25rem);
					@include media-breakpoint-down(md) {
						width: calc(100% - 12.5rem);
					}
					@include media-breakpoint-down(xs) {
						width: calc(100% - 6rem);
					}
				}
				&:last-child{
					width: rem(200px);
					display: flex;
					align-items: center;
					justify-content: flex-end;
					@include media-breakpoint-down(xs) {
						width: 5rem;
					}
				}
			}


			p{
				text-align: center;
				margin: 0 auto;
			}
			.select-lang {
				.open-lang {
					border-radius: 50%;
					background: cl(primary);
					color:cl(white);
					width:2rem;
					height: 2rem;
					border:1px solid  cl(primary);
					display: none;
					justify-content: center;
					align-items: center;
					@include media-breakpoint-down(xs) {
						display: flex;
					}
					&.active {
						color: cl(primary);
						background: cl(white);
					}
					i {
						font-size:rem(16px);
					}
				}
				.lang-inner {
					@include media-breakpoint-down(xs) {
					position: absolute;
					left:0;
					top:-300%;
					width:100%;
					padding: 1rem;
					background:  cl(white);
					transition: all 0.3s ease;
					text-align: center;
						z-index: 1000;
					&.show {
						top:100%;
					}
				}
				}
				.goog-te-gadget-simple {
					background: transparent;
					border:1px solid cl(white);
					.goog-te-menu-value {
						color: cl(white);
					}
				}
			}

			#translate-button.button {
				position: relative;
				font-size: rem(15px);
				color: cl(primary);
				font-family: $font-r;
				font-weight: 700;
				text-transform: uppercase;
				border: 0;
				border-radius: 0;
				padding: 0;
				margin: 0;
				white-space: nowrap;
				@include media-breakpoint-down(xs) {
					font-size: rem(14px);
					color: cl(white);
				}
				&:focus{
					outline: 0;
				}
				i{
					font-size: rem(13px);
					margin-left: 0.1rem;
				}


			}
			#googleTranslate {
				opacity: 0;
				width: 100px !important;
				min-height: 20px;
				top: 0;
				left: unset!important;
				right: 0;
				position: absolute;
				cursor: pointer;
				border: 0;
			}
			.search-toggle{
				margin-left: rem(22px);
				img {
					width: rem(22px);
				}

			}
		}

		&-bottom{
			position: relative;
			//margin-top: -1rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.marque-wrap{
		overflow: hidden;
		color: cl(primary);
		font-family: $font-r;
		font-weight: 400;
		font-size: rem(16px);
		b{
			color: cl(primary);
			font-family: $font-r;
			font-weight: 400;
			margin-right: 2rem;
			font-size: rem(16px);
		}
	}
}

/*hamburger btn*/
.btn-lines {
	position: absolute;
	display: block;
	left: 0;
	width: 100%;
	height:rem(2px);
	background-color: cl(primary);
	content: "";
	transition: all 0.3s ease;
}
.c-hamburger {
	display: none;
	position: relative;
	z-index: 201;
	margin-left: 2rem;
	padding: 0;
	height:rem(20px);
	width:rem(26px);
	flex-shrink: 0;
	font-size: 0;
	text-indent: -9999px;
	appearance: none;
	border: none;
	cursor: pointer;
	transition: all 0.3s ease  0.3s;
	&:focus {
		outline: none;
	}
	@include media-breakpoint-down(lg){
		display: block;
	}
	@include media-breakpoint-up(sm) {
	//	margin-top: 1.25rem;
	}
	span {
		display: block;
		position: absolute;
		top:rem(9px);
		left: 0;
		right: 0;
		height:rem(2px);
		background: cl(primary);
		transition: all 0.3s ease;
	}
	span::before {
		@extend .btn-lines;
		top:rem(-7px);
	}
	span::after {
		@extend .btn-lines;
		bottom:rem(-7px);
	}
	&--htx {
		background-color: transparent;
		&.active {
			&:before {
				display: none;
			}
			span {
				background: none;
			}
			span::before {
				top: 0;
				transform:rotate(45deg);
				background: cl(white);
			}
			span::after {
				bottom: 0;
				transform:rotate(-45deg);
				background: cl(white);
			}
		}
	}
}
/*hamburger btn*/


.not-modal-toggle{
	margin-left: 1rem;
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	color: cl(white);
	background-color: cl(primary);
	border: 1px solid cl(primary);
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover{
		background-color: cl(primary,0.5);
		color: cl(white);
		text-decoration: none;
	}
}