.static {
  font-size: rem(18px);
  line-height: rem(30px);
  color:cl(text-gray);
  font-family: $font-r;
  padding: rem(40px 0 210px 0);
  background:  bottom / 100% auto no-repeat, cl(white);
  p {
    margin: rem(0 0 20px);
  }
  h2 {
    font-size: rem(40px);
    text-transform: uppercase;
    margin: rem(0 0 20px);
    letter-spacing: 0.1em;
    font-family: $font;
    font-weight: 400;
    color: cl(text);
    @include media-breakpoint-down(xs) {
      font-size: rem(35px);
    }
  }

  h3 {
    font-size: rem(30px);
    line-height: rem(40px);
    margin: rem(0 0 20px);
    font-family: $font;
    font-weight: 400;
    color: cl(text);
    @include media-breakpoint-down(xs) {
      font-size: rem(25px);
    }
  }
  h4 {
    font-size: rem(22px);
    line-height: rem(32px);
    margin: rem(0 0 20px);
    font-family: $font;
    font-weight: 400;
    color: cl(text);
    @include media-breakpoint-down(xs) {
      font-size: rem(18px);
    }
  }
  h5 {
    font-size: rem(18px);
    margin: rem(0 0 20px);
    font-weight: 700;
  }
  h6 {
    font-size: rem(16px);
    margin: rem(0 0 20px);
    font-weight: 700;
  }
  ol, ul {
    margin: rem(0 0 20px);
    padding: rem(0 0 0 40px);
  }
  ul{
    list-style: none;
    position: relative;
    z-index: 3;
    margin-bottom: 1.5rem;
    li{
      position: relative;
      padding: rem(2px 0 2px 15px);
      &:after{
        content: '';
        position: absolute;
        top:rem(12px);
        left: 0;
        width: rem(7px);
        height: rem(7px);
        border-radius: 50%;
        line-height: rem(20px);
        background-color: cl(primary);
      }
    }
  }
  img {
    display: block;
    margin: 0 auto 2rem;
    max-width:90%;
    height: auto;
  }
  .account-mobile-toggle{
    margin-bottom: 0.675rem;
    width: 100%;
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
  .left-menu{
    flex-shrink: 0;
    //width: 18.75rem;
   // margin-right: 2.5rem;
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-right:0;
      margin-bottom: 2rem;
    }

    ul{
      border-radius: rem(0px);
      background-color:cl(white);
      position: relative;
      width: 100%;
      list-style: none;
      margin-bottom: 1rem;
      padding: rem(13px 0);
      min-height: rem(180px);
      box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);

      @include media-breakpoint-down(md) {
        display: none;
        min-height: unset;
      }
      li{
        color: cl(text-blue);
        font-size: rem(18px);
        background-color: transparent;
        line-height: rem(24px);
        padding: rem(8px 20px 8px 24px);
        text-transform: none;
        &.active{
          background-color:cl(primary);
          a{
            color: cl(white);
          }
        }
        &:before,
        &:after{
          display: none;
        }
        &:hover{
          background-color:cl(primary,0.8);
          color: cl(white);
        }
        a{
          color:cl(text-blue);
          display: block;
          &:hover{
            text-decoration: none;
            color: cl(white);

          }
        }
      }
    }

  }
}