.members-page{
  padding: rem(67px 0 100px);
  @include media-breakpoint-down(md) {
    padding-bottom: 0;
  }
}
.members-section{
  padding: rem(0px 0 50px);
  .members-block-grid{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: rem(56px);
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      column-gap: 1.5rem;
    }
    .item {
      width: calc(25% - 2.7rem);
      margin-bottom: 2.5rem;
      @include media-breakpoint-down(md) {
        width: calc(33% - 2.5rem);
        margin-bottom: 1.5rem;
      }
      @include media-breakpoint-down(sm) {
        width: calc(50% - 2rem);

      }
      @media (max-width: 400.98px) {
        width: 100%;
      }
      .member-item {
        box-shadow: -5.209px 29.544px 68px 0px rgba(0, 0, 0, 0.21);
        position: relative;
        padding-bottom: 112%;
        color: cl(white);
        text-align: center;
        font-size: rem(22px);
        line-height: rem(28px);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../img/no-avatar.jpg');
        &:before {
          content: "";
          background-image: url(../img/shadow-3.png);
          background-size: cover;
          background-position: center bottom;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }

        .member-item-text {
          position: absolute;
          z-index: 2;
          left: 0;
          width: 100%;
          bottom: 0;
          padding: 0.5rem 0.5rem 1rem;

          .name {
            font-size: rem(22px);
            line-height: rem(28px);
          }

          .position {
            font-size: rem(16px);
            line-height: rem(26px);
            font-family: $font-r;
          }
        }
      }
    }
  }
}

.map-section{
  padding-bottom: rem(30px);
  @include media-breakpoint-down(md) {
    padding-bottom: 0;
  }
  .flex-wraper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .text-side{
      @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
        text-align: center;
        width: 100%;
      }
    }
    .contacts{
      font-size: rem(18px);
      line-height: rem(28px);
      font-family: $font-r;
      color: cl(text-gray);

      a{
        color: cl(text-gray);
      }
      p{
        margin-bottom: 0;
      }
    }
  }
  .map-wrap{
    position: relative;
    width: 38vw;
    height: 23.43vw;
    @include media-breakpoint-down(md) {
      width: 100vw;
      margin:0 -1rem;
      height: 61.63vw;
    }
    .map{
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      left: 0;
      top: 0;
    }
  }
}

.members-page{
  position: relative;
  background: right bottom/100% auto no-repeat, cl(white);
}
