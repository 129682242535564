.our-schools-section{
  padding: rem(75px 0 90px 0);

  &-info{
    font-size: rem(18px);
    line-height: rem(28px);
    font-family: $font-r;
    color: cl(text-gray);
    h2,h3{
      font-size: rem(30px);
      line-height: rem(40px);
      margin: rem(0 0 20px);
      font-family: $font;
      font-weight: 400;
      @include media-breakpoint-down(xs) {
        font-size: rem(25px);
      }
    }
  }
  .schools-grid{
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    column-gap: 2.5rem;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      column-gap: 3rem;
    }
    .school--item{
      width: calc(33% - 1.5rem);
      border: 1px solid #d1d6d9;
      margin-bottom: 1.75rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @include media-breakpoint-down(md) {
        width: calc(50% - 1.5rem);
        margin-bottom: 2.5rem;
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
      &:hover{
        border: 1px solid cl(primary);
        .school--item-img{
          .pic{
            width: 104%;
            height: 105%;
          }
        }
        .school--item-content {
          .address {
            opacity: 0;
          }
          .btn-holder{
            bottom: 0;
          }
        }
      }
      &-img{
        width: 100%;
        flex-shrink: 0;
        padding-bottom: 60.25%;
        position: relative;
        overflow: hidden;
        .pic{
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          @extend .pos-center;
          transition: 0.3s ease-in-out;
        }
      }
      &-content{
        flex-grow: 1;
        padding: rem(25px);
        font-size: rem(16px);
        line-height: rem(26px);
        font-family: $font-r;
        font-weight: 400;
        color: cl(primary);
        overflow: hidden;
        position: relative;

        .name{
          font-family: $font;
          font-size: rem(20px);
          line-height: rem(30px);
          margin-bottom: rem(8px);
        }
        .address{
          margin-top: 1rem;
          transition: 0.3s ease-in-out;
        }
        .btn-holder{
          position: absolute;
          bottom: -100%;
          left: 0;
          width: 100%;
          transition: 0.2s ease-in-out;
          padding: rem(28px);
          .btn{
            width: 100%;
            z-index: 4;
          }
        }
        .social-links{
          display: flex;
          justify-content: center;
          column-gap: 1rem;
          margin-top: 2rem;
          align-items: center;
          @include media-breakpoint-down(xs) {
            justify-content: center;
            margin-bottom: 1rem;
          }
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            @include circle(40px);
            color:cl(primary);
            margin-left: rem(20px);
            border:rem(1px solid cl(primary));
            flex-shrink: 0;
            @include media-breakpoint-down(xs) {
              @include circle(40px);
              margin: 0 1rem;
            }
            &:hover {
              text-decoration: none;
              color: cl(white);
              background-color: cl(primary);
            }
            i {
              font-size: rem(20px);
              @include media-breakpoint-down(xs) {
                font-size: rem(24px);
              }
            }
          }
        }
      }

    }
    &.schools-social-grid{
      column-gap: 2rem;
      @include media-breakpoint-down(md) {
        column-gap: 1rem;
      }
      .school--item{
        width: calc(25% - 1.5rem);
        border: 1px solid #d1d6d9;
        margin-bottom: 1.75rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @include media-breakpoint-down(md) {
          width: calc(33% - .5rem);
          margin-bottom: 1.5rem;
        }
        @include media-breakpoint-down(xs) {
          width: calc(50% - .5rem);
        }
        &:hover{
          border: 1px solid cl(primary);
          .school--item-img{
            .pic{
              width: 104%;
              height: 105%;
            }
          }
          .school--item-content {
            .address {
              opacity: 0;
            }
            .btn-holder{
              bottom: 0;
            }
          }
        }
        &-img{
          width: 100%;
          flex-shrink: 0;
          padding-bottom: 60.25%;
          position: relative;
          overflow: hidden;
          .pic{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            @extend .pos-center;
            transition: 0.3s ease-in-out;
          }
        }
        &-content{
          flex-grow: 1;
          padding: rem(25px);
          font-size: rem(16px);
          line-height: rem(26px);
          font-family: $font-r;
          font-weight: 400;
          color: cl(primary);
          overflow: hidden;
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .name{
            font-family: $font;
            font-size: rem(20px);
            line-height: rem(30px);
            margin-bottom: rem(8px);
          }
          .address{
            margin-top: 1rem;
            transition: 0.3s ease-in-out;
          }
          .btn-holder{
            position: absolute;
            bottom: -100%;
            left: 0;
            width: 100%;
            transition: 0.2s ease-in-out;
            padding: rem(28px);
            .btn{
              width: 100%;
              z-index: 4;
            }
          }
          .social-links{
            display: flex;
            justify-content: center;
            column-gap: 1rem;
            margin-top: 2rem;
            align-items: center;
            @include media-breakpoint-down(md) {
              column-gap: 1rem;
            }
            @include media-breakpoint-down(xs) {
              justify-content: center;
              margin-top: 1rem;
            }
            a {
              display: flex;
              justify-content: center;
              align-items: center;
              @include circle(40px);
              color:cl(primary);
              margin: 0;
              border:rem(1px solid cl(primary));
              flex-shrink: 0;
              @include media-breakpoint-down(xs) {
                @include circle(35px);
              }
              &:hover {
                text-decoration: none;
                color: cl(white);
                background-color: cl(primary);
              }
              i {
                font-size: rem(20px);
                @include media-breakpoint-down(xs) {
                  font-size: rem(20px);
                }
              }
            }
          }
        }

      }
    }
  }
}
.common--tabs{
  position: relative;
  z-index: 1;
  color: cl(primary);
  .nav-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(23px);
    &.nav-wrap-small{
      nav{
        width: 100%;
      }
      .nav{

        &.nav-tabs{
          border: 1px solid cl(primary);
          border-radius: rem(30px);
          padding:rem(4px);
          column-gap: 0;
          text-align: center;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include media-breakpoint-down(lg) {
            justify-content: center;
          }
          @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            column-gap: 0;
            row-gap: 1rem;
            justify-content: space-between;
            border: 0;
          }
          .nav-link{
            border-radius: rem(25px);
            background-color: transparent;
            border: 1px solid transparent;
            font-size: rem(16px);
            white-space: normal;
            color: cl(primary);
            padding: rem(9px 13px);
            line-height: rem(22px);
            font-family: $font-r;
            font-weight: 400;
            letter-spacing: 0;
            position: relative;
            @include media-breakpoint-down(lg) {
              min-width: 25%;
            }
            @include media-breakpoint-down(sm) {
              width: 48%;
              border: 1px solid cl(primary);
            }
            @include media-breakpoint-down(xs) {
              width: 100%;
              border: 1px solid cl(primary);
            }
            &.active{
              background-color: cl(primary);
              color: cl(white);
              font-weight: 700;
              &:hover{
                &:before{
                  width: 0;
                }
                @include media-breakpoint-down(lg) {
                  text-decoration: none;
                }
                @include media-breakpoint-down(xs) {
                  background-color: cl(primary);
                }
              }
            }
            &:before{
              content: "";
              @extend .pos-centerX;
              bottom: 0.5rem;
              width: 0;
              height: 2px;
              transition: all 0.3s linear;
              background-color: cl(primary);
              @include media-breakpoint-down(lg) {
                display: none;
              }
            }
            &:hover{
              &:before{
                width: calc(100% - 2rem);
              }
              @include media-breakpoint-down(lg) {
                text-decoration: underline;
              }
              @include media-breakpoint-down(sm) {
                background-color: cl(primary, 0.15);
              }
            }
          }
        }

      }
    }
    .nav{
      &.nav-tabs{
        border: 1px solid cl(primary);
        border-radius: rem(30px);
        padding:rem(4px);
        column-gap: rem(15px);
        text-align: center;
        @include media-breakpoint-down(xs) {
          flex-wrap: wrap;
          column-gap: 0;
          row-gap: 1rem;
          justify-content: space-between;
          border: 0;
        }
        .nav-link{
          border-radius: rem(25px);
          background-color: transparent;
          border: 1px solid transparent;
          font-size: rem(18px);
          white-space: nowrap;
          color: cl(primary);
          padding: rem(9px 21px);
          line-height: rem(30px);
          font-weight: 600;
          min-width: rem(145px);
          letter-spacing: 0.1em;
          position: relative;
          @include media-breakpoint-down(xs) {
            width: 48%;
            border: 1px solid cl(primary);
          }
          &.active{
            background-color: cl(primary);
            color: cl(white);
            &:hover{
              &:before{
                width: 0;
              }
              @include media-breakpoint-down(xs) {
                background-color: cl(primary);
              }
            }
          }
          &:before{
            content: "";
            @extend .pos-centerX;
            bottom: 0.5rem;
            width: 0;
            height: 2px;
            transition: all 0.3s linear;
            background-color: cl(primary);
            @include media-breakpoint-down(xs) {
              display: none;
            }
          }
          &:hover{
            &:before{
              width: calc(100% - 4.25rem);
            }
            @include media-breakpoint-down(xs) {
              background-color: cl(primary, 0.15);
            }
          }
        }
      }

    }
  }
  h2,h3{
    font-size: rem(30px);
    line-height: rem(40px);
    margin: rem(0 0 20px);
    font-family: $font;
    font-weight: 400;
    color: cl(text-gray);

    @include media-breakpoint-down(xs) {
      font-size: rem(25px);
    }
  }
  .schools-grid{
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    column-gap: 2.5rem;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      column-gap: 3rem;
    }
    .school--item{
      width: calc(33% - 1.5rem);
      border: 1px solid #d1d6d9;
      margin-bottom: 1.75rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @include media-breakpoint-down(md) {
        width: calc(50% - 1.5rem);
        margin-bottom: 2.5rem;
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
      &:hover{
        border: 1px solid cl(primary);
        .school--item-img{
          .pic{
            width: 104%;
            height: 105%;
          }
        }
        .school--item-content {
          .address {
            opacity: 0;
          }
          .btn-holder{
            bottom: 0;
          }
        }
      }
      &-img{
        width: 100%;
        flex-shrink: 0;
        padding-bottom: 60.25%;
        position: relative;
        overflow: hidden;
        .pic{
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
         @extend .pos-center;
          transition: 0.3s ease-in-out;
        }
      }
      &-content{
        flex-grow: 1;
        padding: rem(25px);
        font-size: rem(16px);
        line-height: rem(26px);
        font-family: $font-r;
        font-weight: 400;
        color: cl(primary);
        overflow: hidden;
        position: relative;

        .name{
          font-family: $font;
          font-size: rem(20px);
          line-height: rem(30px);
          margin-bottom: rem(8px);
        }
        .address{
          margin-top: 1rem;
          transition: 0.3s ease-in-out;
        }
        .btn-holder{
          position: absolute;
          bottom: -100%;
          left: 0;
          width: 100%;
          transition: 0.2s ease-in-out;
          padding: rem(28px);
          .btn{
            width: 100%;
            z-index: 4;
          }
        }
      }
    }
  }
}
.apply-schools-section{
  padding: rem(45px 0 60px 0);
  position: relative;
}
.schools-for-apply{
  .item{
    position: relative;
    padding-left: rem(54px);
    margin-bottom: 1.5rem;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-top: 4rem;
    }
    &:hover{
      .item--inner{
        border: 1px solid cl(primary);
      }
    }
    &--inner{
      border: 1px solid #d1d6d9;
      display: flex;
      padding: rem(24px);
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }
      .img-wrap{
        width: rem(390px);
        height: rem(255px);
        position: relative;
        border: rem(10px solid cl(white));
        margin-left: rem(-64px);
        @include media-breakpoint-down(md) {
          margin: -4rem auto 2rem;
        }
        &-inner{
          box-shadow: 1px 17px 24px 0px rgba(0, 0, 0, 0.1);
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          @extend .pos-center;
          transition: 0.3s ease-in-out;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      &-content{
        width: calc(100% - 20.375rem);
        padding: rem(12px 0 0 45px);
        font-size: rem(16px);
        line-height: rem(26px);
        font-family: $font-r;
        font-weight: 400;
        color: cl(primary);
        position: relative;
        @include media-breakpoint-down(md) {
          width: 100%;
          padding: rem(12px 0 0 0px);
        }
        .name{
          font-size: rem(30px);
          line-height: rem(40px);
          margin: rem(0 0 20px);
          font-family: $font;
          font-weight: 400;
          color: cl(text);

          @include media-breakpoint-down(xs) {
            font-size: rem(25px);
          }
        }
        .class{
          font-size: rem(20px);
          font-weight: 700;
          margin-bottom: 1rem;
        }
        .address{
          margin-bottom: 1.5rem;
        }
        .school-info{
          display: block;
        }
        &.active{
          .school-info{
            display: none;
          }
          .iframe-holder{
            display: block;
          }
        }
        &.active-2{
          .school-info{
            display: none;
          }
          .iframe-holder-2{
            display: block;
          }
        }
        .iframe-holder{
          position: relative;
          display: none;
          padding-top: 1rem;
          .close-text-block{
            position: absolute;
            top: -1rem;
            right: -1rem;
            width: 2rem;
            img{
              width: 100%;
            }
          }
        }
        .iframe-holder-2{
          position: relative;
          display: none;
          padding-top: 1rem;
          .close-text-block{
            position: absolute;
            top: -1rem;
            right: -1rem;
            width: 2rem;
            img{
              width: 100%;
            }
          }
        }
      }
    }
  }
}
